.active-field{
    border:solid 1px #fff;
    border-radius:6px;
    min-height:30px;
}
.cardMediaBg {
    object-fit:fill!important;
    background: #8e9eab; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #eef2f3, #8e9eab); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
    .cardMediaBg:hover {
    border:1px solid #fff;
}