.feature-file-upload {
    height: 100%;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
}

.feature-file-upload #input-file-upload {
    display: none;
}

    .feature-file-upload #label-file-upload {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 2px;
        border-radius: 1rem;
        border-style: dashed;
        border-color: #cbd5e1;
        background-color: #f8fafc;
    }

    .feature-file-upload .upload-button {
        cursor: pointer;
        padding: 0.25rem;
        font-size: 1rem;
        border: none;
        font-family: 'Oswald', sans-serif;
        background-color: transparent;
    }

        .feature-file-upload .upload-button:hover {
            text-decoration-line: underline;
        }
